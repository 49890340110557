button.ant-btn{
  height: auto !important;
  padding: .5rem 1rem;
  transition: none;
  box-shadow: none;
  border-radius: 2px;
}

.btn-clear {
  background-color: transparent !important;
  border: none !important;
  font-weight: bold;
  padding: 0 !important;
}
.btn-clear div {
  float: right;
}

.ant-btn-primary, .ant-btn-primary:focus, .ant-btn-primary:active,
.btn-primary, .btn-primary:focus, .btn-primary:active {
  background-color: $primary-color !important;
  border-color: $primary-color !important;
  color: $white !important;
}
.ant-btn-primary:hover,
.btn-primary:hover {
  background-color: $white !important;
  border-color: $primary-color !important;
  color: $primary-color !important;
}

.btn-secondary, .btn-secondary:focus, .btn-secondary:active {
  background-color: $secondary-color !important;
  border-color: $secondary-color !important;
  color: $white !important;
}
.btn-secondary:hover{
  background-color: $white !important;
  border-color: $secondary-color !important;
  color: $secondary-color !important;
}

.btn-tertiary, .btn-tertiary:focus, .btn-tertiary:active {
  background-color: $tertiary-color !important;
  border-color: $tertiary-color !important;
  color: $white !important;
}
.btn-tertiary:hover{
  background-color: $white !important;
  border-color: $tertiary-color !important;
  color: $tertiary-color !important;
}

.btn-success, .btn-success:focus, .btn-success:active {
  background-color: $success-color !important;
  border-color: $success-color !important;
  color: $white !important;
}
.btn-success:hover {
  background-color: $white !important;
  border-color: $success-color !important;
  color: $success-color !important;
}

.btn-warning, .btn-warning:focus, .btn-warning:active {
  background-color: $warning-color !important;
  border-color: $warning-color !important;
  color: $white !important;
}
.btn-warning:hover{
  background-color: $white !important;
  border-color: $warning-color !important;
  color: $warning-color !important;
}

.btn-danger, .btn-danger:focus, .btn-danger:active {
  background-color: $danger-color !important;
  border-color: $danger-color !important;
  color: $white !important;
}
.btn-danger:hover{
  background-color: $white !important;
  border-color: $danger-color !important;
  color: $danger-color !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: $secondary-color !important;
  color: $white !important;
}

.ant-radio-button-wrapper {
  color: $disabled-color !important;
}

.ant-btn[disabled] {
  background-color: $disabled-color !important;
  border-color: $disabled-color !important;
  color: $white !important;
}

.ant-switch-checked {
  background-color: $secondary-color !important;
}

button.ant-btn.btn-list{
  height: 40px !important;
}

button.ant-btn.btn-search{
  height: 41px !important;
  border-radius: 0 !important;
}
