div.ant-card{
  border-radius: $border-radius;
  padding: 0px;
}

div.ant-card-body{
  padding: 24px 32px;
}

div.ant-card-head{
  padding: 24px 32px;
  padding-bottom: 0px
}

div.ant-card-head-title{
  padding: 0px;
}

.dashboard-card{
  width: 300px;
  border: 2px solid $disabled-form-color;
  background-color: $white;
  color: $black;
  border-radius: $border-radius;

  &:hover{
    border-color: #6480bd;
    color: #6480bd;
  }

  &.dashboard-card-primary{
    border: 2px solid $primary-color;
    background-color: $primary-color;
    color: $white;

    &:hover{
      background-color: $white;
      color: $primary-color;
    }
  }

  &.dashboard-card-secondary{
    border: 2px solid $secondary-color;
    background-color: $secondary-color;
    color: $white;

    &:hover{
      background-color: $white;
      color: $secondary-color;
    }
  }

  &.dashboard-card-tertiary{
    border: 2px solid $tertiary-color;
    background-color: $tertiary-color;
    color: $white;

    &:hover{
      background-color: $white;
      color: $tertiary-color;
    }
  }

  .anticon{
    font-size: 36px;
  }
  .dashboard-card-text{
    font-size: 21px;
    text-align: center;
  }

  &.dashboard-card-primary,
  &.dashboard-card-secondary,
  &.dashboard-card-tertiary{
    .anticon{
      font-size: 48px;
    }
  }
}
