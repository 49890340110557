form.ant-form{
  h3{
    margin-bottom: 1rem;
  }
  .ant-form-item-label{
    padding-bottom: 0;
    font-weight: bold;
  }
}

/* ITEMS */
div.ant-form-item {
  margin-bottom: 0px;
}

div.ant-form-item-has-error{
  margin-bottom: 20px;
}

/* LABELS */
.ant-form-item-label > label.ant-form-item-required::before{
  color: $danger-color !important;
}

/* INPUTS */
input.ant-input{
  width: 100%;
  padding: 8px 16px;
  font-size: 15px;
  border: 1px solid  $disabled-form-color;
  border-radius: 0;
}

input.ant-input.readonly-form-field {
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  padding: 8px 16px;
  font-size: 15px;
  cursor: not-allowed;
  background-color: $disabled-form-bg-color;
  border: 1px solid $disabled-form-color;
  color: $disabled-color;
  border-radius: 0;
}

textarea.ant-input.readonly-textarea {
  cursor: not-allowed;
  background-color: $disabled-form-bg-color;
  border: 1px solid $disabled-form-color;
  color: $disabled-color;
  box-shadow: none !important;
}

.ant-input-group .ant-input-group-addon{
  background-color: $white;
  border-radius: 0;
}

/* SELECTS */
.ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  padding: 8px 16px;
  font-size: 15px;
  border: 1px solid  $disabled-form-color;
  border-radius: 0;
  height: 41px;
}

.ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
  height: 100%;
}

.ant-select.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select.ant-select-single .ant-select-selector .ant-select-selection-placeholder{
  line-height: inherit;
}

/* TEXT AREAS */
textarea.ant-input{
  padding: 8px 16px;
  font-size: 15px;
  border: 1px solid  $disabled-form-color;
  border-radius: 0;
}

/* IMG UPLOADS */
.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select-picture-card{
  margin-bottom: 0;
  margin-right: 0;
  border-radius: 0;
  background-color: $white;
}
.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select-picture-card .ant-upload .ant-upload-button{
  color: $black;
  opacity: .4;
}

.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select-picture-card.ant-upload-disabled{
  background-color: $disabled-form-bg-color;
}
.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select-picture-card.ant-upload-disabled .ant-upload.ant-upload-disabled .ant-upload-button{
  color: $disabled-color;
  opacity: 1;
}

.ant-form-item-has-error .ant-upload-picture-card-wrapper .ant-upload.ant-upload-select-picture-card{
  border-color: $danger-color;
}

/* RADIO GROUPS */
.ant-radio-group{
  .ant-radio-button-wrapper{
    border-radius: 0 !important;
    padding: 8px 16px;
    line-height: 1.5715;
    font-size: 15px;
    height: auto;

    &.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']){
      border-color: $tertiary-color !important;
      background-color: $tertiary-color !important;
    }
  }
}

/* DATE PICKERS */
.ant-picker.ant-picker-range{
  padding: 8px 11px;
  border-radius: 0;
  width: 100%;

  .ant-picker-input > input{
    font-size: 15px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

/* FIRST FOM ITEM STICKY TOP */
.form-first-item-sticky-top-container{
  margin: -44px -24px 40px;
  padding-top: 20px;
  position: sticky;
  top: -32px;
  background-color: #f2f2f2;
  z-index: 2;
  box-shadow: 0 10px 10px -10px rgba(0,32,91,0.2);
}
.form-first-item-sticky-top-content{
  padding: 20px 24px 20px;
  background-color: $white;
  border-radius: 0.3em .3rem 0 0;
}

/* ERRORS */
.ant-form-item.ant-form-item-has-error {

  input.ant-input:enabled,
  textarea.ant-input:enabled,
  .ant-picker:not(.ant-picker-disabled) .ant-picker-input {
    border-color: $danger-color;
    color: $danger-color;

    &:focus {
      border-color: $danger-color;
    }

    &:hover {
      border-color: $danger-color;
    }

    &::placeholder {
      color: $danger-color;
    }
  }

  .ant-select:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select:not(.ant-select-disabled).ant-select-multiple:not(.ant-select-customize-input) .ant-select-selector {
    .ant-select-selection-placeholder {
      color: $danger-color;
    }
  }

  .ant-form-item-control {
    position: relative;

    .ant-form-item-explain {
      position: absolute;
      font-size: 13px;
      bottom: -25px;
    }
  }

  &.merged {
    .ant-input-group {
      border-color: $danger-color;

      .ant-input-group-addon {
        border: 0;
      }

      .ant-input {
        border: 0;
      }
    }
  }

  & + .custom-error {
    color: $danger-color;
    margin-top: -23px;
    font-size: 13px;
    line-height: 1.5715;
  }
}