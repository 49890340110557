.text-primary{
  color: $black !important;
  font-weight: 600;
}
.text-secondary{
  color: $secondary-color !important;
}
.text-tertiary{
  color: $tertiary-color !important;
}
.text-success{
  color: $success-color !important;
}
.text-warning{
  color: $warning-color !important;
}
.text-white{
  color: $white !important;
}
.text-black{
  color: $black !important;
}