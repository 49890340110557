/* BOOTSTRAP */
@import "~bootstrap/scss/bootstrap.scss";

/* CORE */
@import "./core/variables";
@import "./core/backgrounds";
@import "./core/texts";
@import "./core/utilities";
@import "./core/cursor";

/* MIXINS*/
@import "./mixins/mixins";

/* LAYOUT */
@import "./layout/header";
@import "./layout/layout";
@import "./layout/typos";

/* COMPONENTS */
@import "./components/buttons";
@import "./components/cards";
@import "./components/sidemenu";
@import "./components/tables";
@import "./components/lists";
@import "./components/forms";
@import "./components/breadcrumbs";
@import "./components/ribbons";
@import "./components/icons";
@import "./components/tree";

@import 'node_modules/@fortawesome/fontawesome-svg-core/styles';
/* VIEWS */
// Ajouter ici les styles spécifiques à une vue (en dernier recours)
