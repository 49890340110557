/* COULEURS */
// Certaines couleurs de base de antd sont dans config-overrides.js
$primary-color: #D86E2A;
$secondary-color: #405faf;
$tertiary-color: #2C2D2C;
$success-color: #00b2a9;
$warning-color: #fecb00;
$danger-color: #e2003c;
$disabled-color: #888888;
$disabled-form-color: #c4c4c4;
$disabled-form-bg-color: #f5f5f5;
$dark-grey: #828282;
$grey: #F6F4F5;
$white: #ffffff;
$black: #000000;
$pink: #FD696D;

/* FONTS */
$font-size: 14px;
$line-height: 17px;
$letter-spacing: normal;

/* BORDER */
$border-radius: .75em;

/* BOX SHADOW */
$box-shadow: 0 0 10px 0 rgba(0,32,91,0.2);

/* BREAKPOINTS */
$max-xl-breakpoint: 1199.98px; // Extra large devices (large desktops, 1200px and up)
$max-lg-breakpoint: 991.98px;  // Large devices (desktops, 992px and up)
$max-md-breakpoint: 767.98px;  // Medium devices (tablets, 768px and up)
$max-sm-breakpoint: 575.98px;  // Small devices (landscape phones, 576px and up)

$xl-breakpoint: 1200px; // Extra large devices (large desktops, 1200px and up)
$lg-breakpoint: 992px;  // Large devices (desktops, 992px and up)
$md-breakpoint: 768px;  // Medium devices (tablets, 768px and up)
$sm-breakpoint: 576px;  // Small devices (landscape phones, 576px and up)