$headerAvatarSize: 42px;
$headerLogoSize: 40px;

header{
  background-color: $white;
  height: 60px;

  .logo{
    height: 20px;
  }

  .subtitle {
    padding: .2em .6em;
    background-color: $black;
    color: $white;
    border-radius: 8px;
  }

  .user{
    .user-name{
      font-size: 1rem;
      line-height: 1rem;
      font-weight: normal;
    }
    .user-logout{
      font-size: .9rem;
      line-height: 1.1rem;
      color: $black;
      font-weight: bold;
    }
    .user-avatar{
      height: $headerAvatarSize;
      width: $headerAvatarSize;

      .ant-avatar{
        height: $headerAvatarSize;
        width: $headerAvatarSize;
      }
    }
  }
}