$font-path: '../../static/fonts/';

@font-face {
  font-family: "Lato";
  src: url($font-path + "Lato-Regular.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "RobotoSlab";
  src: url($font-path + "RobotoSlab-Regular.ttf") format("truetype");
  font-weight: normal;
}

body {
  font-family: "Lato", Roboto, sans-serif;
}

header {
  border-bottom: 2px solid $dark-grey
}

.content-container{
  padding: 36px 42px;
  .content{
    .content-body{
      padding: 0;
      max-width: 1200px;
      white-space: pre-line;

      &.dashboard-body{
        max-width: 712px;
      }

      &.login-body {
        max-width: 712px;
      }

      &.list-body{
        max-width: 100%;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .content-container{
    padding: 0px;
    border-radius: 0px;
  }
}