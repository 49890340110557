.ant-layout-sider{
  transition: none;
  position: relative;
  top: 0;
  background: $tertiary-color !important;
}

.ant-layout{
  overflow: auto;
}
@media screen and (max-width: 991.98px) {
  .ant-layout-sider{
    position: absolute;
    top: 60px;
    bottom: 0;
    z-index: 1;
  }
}

.ant-layout-sider-zero-width-trigger{
  top: 0;
}

.ant-menu-inline .ant-menu-item{
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  height: auto !important;
}

.ant-menu-item {
  border-bottom: 1px solid $white;
}
