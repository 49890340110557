.ant-tree-node-content-wrapper{
  padding: 0px !important;
  width: 100%;
}

.ant-tree-treenode {
  width: 100% !important;
}

.tree-node {
  width: 100%;
  padding: 4px 8px ;
  border-radius: 4px;
  background-color: #c4c4c4;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-popover-inner-content {
  padding: 4px !important;
}

.tree-node {
  cursor: default;
}
.tree-node:hover {
  filter: brightness(0.9);
}

.action-ellipsis {
  cursor: pointer;
}

.status-indicator {
  width: 32px;
  text-align: center;
}

.action-button-container {
  padding: 0;
}

.action-button {
 cursor: pointer;
  margin: 0px 6px;
}
.action-button:hover {
  opacity: .4;
}

.status-up {
 background-color: #CBF0C8;
 color: #219653;
}

.status-down {
 background-color: #FFCECE;
 color: #DD4530;
}

.status-partial-down {
 background-color: #FFCFA3;
 color: #E78C39;
}

.status-disabled {
 background-color: #FBF8E5;
 color: #D4AE37;
}

.status-maintenance {
 background-color: #9EC4FC;
 color: #3E68A8;
}

.status-default {
 background-color: #E5E5E5;
 color: #4f4f4f;
}

.status-bar-container {
  display: flex;
  flex-direction: row;
  width: 50px;
  justify-content: space-between;
  justify-items: center;
  align-content: center;
  align-items: center;
  height: 100%;
}

.status-bar {
  width: 4px;
  height: 12px;
}

.status-bar-up {
 background-color: #219653;
}

.status-bar-down {
 background-color: #DD4530;
}

.status-bar-partial-down {
 background-color: #E78C39;
}

.status-bar-disabled {
 background-color: #D4AE37;
}

.status-bar-maintenance {
 background-color: #3E68A8;
}

.status-bar-default {
 background-color: #BDBDBD;
}

.status-icon-up {
 color: #219653;
}

.status-icon-down {
 color: #DD4530;
}

.status-icon-partial-down {
 color: #E78C39;
}

.status-icon-disabled {
 color: #D4AE37;
}

.status-icon-maintenance {
 color: #3E68A8;
}

.status-icon-default {
 color: #BDBDBD;
}
